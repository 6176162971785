<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련법규 -->
          <c-select
            codeGroupCd="RELATED_LAWS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="relatedLawsCd"
            label="LBL0010245"
            v-model="searchParam.relatedLawsCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 인허가 종류 목록 -->
    <c-table
      ref="licensKindTable"
      title="LBL0010305"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <!-- 추가 -->
          <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addRow" />
          <!-- 저장 -->
          <c-btn label="LBLSAVE" v-if="editable" icon="save" @btnClicked="saveLicenseKind" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'license-kind',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        relatedLawsCd: null
      },
      grid: {
        columns: [
          {
            name: 'relatedLawsCd',
            field: 'relatedLawsCd',
            // 관련법규
            label: 'LBL0010245',
            align: 'center',
            style: 'width:350px',
            type: 'select',
            codeGroupCd: 'RELATED_LAWS_CD',
            sortable: false,
            requried: true,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            // 인허가종류명
            label: 'LBL0010299',
            align: 'left',
            type: 'text',
            sortable: false,
            requried: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 순번
            label: 'LBLSORTORDER',
            align: 'center',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      checkUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.lim.lincense.kind.list.url;
      this.saveUrl = transactionConfig.sop.lim.lincense.kind.save.url;
      this.deleteUrl = transactionConfig.sop.lim.lincense.kind.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addRow() {
      this.grid.data.splice(0, 0, {
        limLicenseKindId: uid(),  // 인허가종류 알련번호
        relatedLawsCd: null,  // 관련법규
        limLicenseKindName: '',  // 인허가종류명
        sortOrder: '',  // 정렬순서
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      });
    },
    removeRow() {
      let selectData = this.$refs['licensKindTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['licensKindTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveLicenseKind() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
